import React, {FunctionComponent, useEffect, useState} from 'react';
import {CatalogueApi, PackListPacksInner} from '@focusrite-novation/ampify-api';
import {validate as isValidUuid} from 'uuid';
import {graphql} from 'gatsby';
import {PageProps} from '../types';
import {Layout} from '../components/Layout';
import {AudioPlayer} from '../components/AudioPlayer';
import {PlayerWrapper} from '../components/AudioPlayer/AudioPlayer.styles';
import {getQueryParameter} from '../utils/url';
import {PlayerPageQuery} from './__generated__/PlayerPageQuery';
import PageWidth from '../components/PageWidth';
import {createApi} from '../api';

type PlayerPageProps = PageProps<PlayerPageQuery>;

// go direct to the prod api for now as the dev api doesn't seem
// to have much data for groups
const apiUrl = 'https://api.ampifymusic.com';

async function getGroupList(): Promise<PackListPacksInner[]> {
  const groupId = getQueryParameter('group_id');

  if (groupId && isValidUuid(groupId)) {
    const api = createApi(CatalogueApi, apiUrl);
    const response = await api.groupPackList(groupId);
    return response.data.packs;
  }

  return null;
}

async function getPackList(): Promise<PackListPacksInner[]> {
  try {
    const response = await new CatalogueApi().packList();
    return response.data.packs;
  } catch (error) {
    console.error(error);
  }

  return [];
}

const Player: FunctionComponent<PlayerPageProps> = (props) => {
  const [packs, setPacks] = useState<PackListPacksInner[]>([]);

  const requestPackList = async () => {
    let packData: PackListPacksInner[] = [];

    const groupList = await getGroupList();

    if (groupList) {
      packData = packData.concat(groupList);
    } else {
      const packs = await getPackList();

      if (packs) {
        packData = packData.concat(packs);
      }
    }

    setPacks(packData);
  };

  useEffect(() => {
    requestPackList();
  }, []);

  return (
    <Layout>
      <PlayerWrapper>
        <PageWidth wide>
          <AudioPlayer packs={packs} shadow={true} />
        </PageWidth>
      </PlayerWrapper>
    </Layout>
  );
};

export const pageQuery = graphql`
  query PlayerPageQuery {
    site {
      siteMetadata {
        apiUrl
        buildEnv
      }
    }
  }
`;

export default Player;
